import React from "react"
import { slide as Menu } from 'react-burger-menu'
import scrollTo from "gatsby-plugin-smoothscroll"

export class BurgerMenu extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            menuOpen:false
        };
    }

    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    handleStateChange (state) {
        this.setState({menuOpen: state.isOpen})
    }

    handleMenuClick (event, selector) {
        event.preventDefault();
        // Using the parent component's state to keep track of the menu
        this.setState({menuOpen: false});
        scrollTo(selector);
    }

    render () {
        return (

            <Menu isOpen={this.state.menuOpen} onStateChange={(state) => this.handleStateChange(state)}>
                  <button className="menu-item" onClick={(e) => this.handleMenuClick(e, "#about")}><i className="arrow"></i>  About</button>
                  <button className="menu-item" onClick={(e) => this.handleMenuClick(e, "#photos")}><i className="arrow"></i>  Photos</button>
                  <button className="menu-item" onClick={(e) => this.handleMenuClick(e, "#whatsincluded")}><i className="arrow"></i>  What's Included</button>
                  <button className="menu-item" onClick={(e) => this.handleMenuClick(e, "#facilities")}><i className="arrow"></i>  Facilities</button>
                  <button className="menu-item" onClick={(e) => this.handleMenuClick(e, "#pricing")}><i className="arrow"></i>  Pricing</button>
                  <button className="menu-item" onClick={(e) => this.handleMenuClick(e, "#team")}><i className="arrow"></i>  Team</button>
                  <button className="menu-item" onClick={(e) => this.handleMenuClick(e, "#contact")}><i className="arrow"></i>  Contact</button>
            </Menu>
        );
    }
}