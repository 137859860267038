/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import Navbar from "./navbar"
import "../styles/mains.scss"

const Layout = ({ children }) => {
  return (
    <>
        <div id="top-level">
          <Navbar></Navbar>
            <div className="main-container" id="main-container">
                <main>{children}</main>
            </div>
        </div>
    </>
  )
}
export default Layout
