import React from "react"
import {BurgerMenu} from "./subcomponents/burgermenu";

import data from "../yourdata";

const Navbar = () => {
  return (
    <div className="section">
          <BurgerMenu  pageWrapId={'gatsby-focus-wrapper'} outerContainerId={'top-level'}></BurgerMenu>
        <div className="social-icons">
            {data.social.map((socialLink, index) => (
                <a
                    key={index}
                    href={socialLink.url}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={socialLink.img} alt="icons"></img>
                </a>
            ))}
        </div>
    </div>
  )
}

export default Navbar
