// Social Icon
import emailIcon from "./images/email.png"
import airbnbIcon from "./images/airbnb-logo.png"

export default {
  //(Please Do Not Remove The comma(,) after every variable)
  //Change The Website Template

  //   Header Details ---------------------
  mainPhotos: [
    {
      id: 1,
      imageURL: "https://d2v35ww0dp4pjd.cloudfront.net/front_2.jpeg",
      blurb: "Front of the house"
    },
    {
      id: 2,
      imageURL: "https://d2v35ww0dp4pjd.cloudfront.net/2.jpeg",
      blurb: "Back of the house"
    },
    {
      id: 3,
      imageURL: "https://d2v35ww0dp4pjd.cloudfront.net/10.jpeg",
      blurb: "Lakeside"
    }
  ],



  titleImage: "https://d2v35ww0dp4pjd.cloudfront.net/menagerie-1.jpeg",
  name: "Menagerie Venue",
  headerTagline: [
    //Line 3 For Header
    "The Menagerie.",
    //Line 1 For Header
    "Wedding Venue",
    //Line 2 For Header
    "and Holiday retreat",
  ],
  //   Header Paragraph
  headerParagraph:
    "This Georgian home is a replica of Marie Antoinettes' and exudes character and quality. Set in 'Capability' Brown parkland overlooking a lake, you couldn't hope for a more peaceful setting so close to the city.",

  //Contact Email
  contactEmail: "enquiries@menagerievenue.co.uk",

  // End Header Details -----------------------

  // About section ---------------------

  aboutText: [
    "The Menagerie is an exclusive-use wedding venue perfect for both intimate weddings and large gatherings.",
    "This beautiful Georgian house is nestled in it's own grounds and is completely surrounded by 500 acres of parkland and backs onto a picturesque lake, whilst the 'Capability Brown' landscape offers stunning photo-worthy vistas in every direction.",
    "Get married on the lawn or in a woodland setting, both overlooking the stunning lake. Or walk down the aisle towards the sheep flock with the splendour of the Georgian facade of the house as your awe-inspiring backdrop.",
    "Your wedding will be uniquely yours, as you take possession of this seven bedroom country house and its glorious grounds and style them how you'd like for your dream day. The house and grounds provide the most beautiful blank canvas for you to paint your dream - Imagine a truly tailored, unique, and personalized experience."
  ],

  // End About section ---------------------

  // Photos section

  photos: [
    {
      id: 1,
      imageURL: "https://d2v35ww0dp4pjd.cloudfront.net/1.jpeg",
      blurb: "Front of the house"
    },
    {
      id: 2,
      imageURL: "https://d2v35ww0dp4pjd.cloudfront.net/2.jpeg",
      blurb: "Back of the house"
    },
    {
      id: 3,
      imageURL: "https://d2v35ww0dp4pjd.cloudfront.net/3.jpeg",
      blurb: "Formal Living Room"
    },
    {
      id: 4,
      imageURL: "https://d2v35ww0dp4pjd.cloudfront.net/4.jpeg",
      blurb: "Master Bedroom"
    },
    {
      id: 5,
      imageURL: "https://d2v35ww0dp4pjd.cloudfront.net/5.jpeg",
      blurb: "Library"
    },
    {
      id: 6,
      imageURL: "https://d2v35ww0dp4pjd.cloudfront.net/6.jpeg",
      blurb: "Bathroom"
    },
    {
      id: 7,
      imageURL: "https://d2v35ww0dp4pjd.cloudfront.net/7.jpeg",
      blurb: "Front Bedroom"
    },
    {
      id: 8,
      imageURL: "https://d2v35ww0dp4pjd.cloudfront.net/8.jpeg",
      blurb: "Kitchen"
    },
    {
      id: 9,
      imageURL: "https://d2v35ww0dp4pjd.cloudfront.net/9.jpeg",
      blurb: "Garden"
    },
    {
      id: 10,
      imageURL: "https://d2v35ww0dp4pjd.cloudfront.net/10.jpeg",
      blurb: "Lakeside"
    },
    {
      id: 11,
      imageURL: "https://d2v35ww0dp4pjd.cloudfront.net/11.jpeg",
      blurb: "Yoga"
    },
    {
      id: 12,
      imageURL: "https://d2v35ww0dp4pjd.cloudfront.net/12.jpeg",
      blurb: "Teepee"
    }
  ],

  //   End Photos Section ---------------------

  // Included section ---------------------

  includedText: [
    "When you book your wedding at the Menagerie, you will be taking over the entire venue from Monday to Wednesday (getting married on the Tuesday) or Wednesday to Friday (getting married on Thursday)",
    "Up to 20 guests will share the main seven bedroom Georgian Menagerie overnight with you for two nights. Or you can have the first night with family and friends and spend the second night as your honeymoon night, alone with your new spouse! You can dine together informally the evening before your wedding and breakfast together on your big day.",
    "You will also use the first day and evening to welcome your caterers, decorators and anyone else who is coming to help prepare your unique wedding.",
    "On the day of your wedding you are free to organise the flow and the pace of the day from start to finish. You may want a quiet morning and for guests to arrive in the afternoon, or you may want certain guests to arrive before others…just give each guest their own arrival time on their wedding invites!",
    "We are a fully licensed venue. You can get married anywhere in the grounds, just organise a celebrant to arrive at your chosen time.",
    "We want your day to unfold just like you see it in your minds eye. Your dedicated wedding planner is on hand to discreetly make sure that guests are following your plans. There is no fear of another bride arriving while you are still here. The whole house and grounds are yours and yours alone across the three days.",
    "You can dine in the fabulous marquee or outside in the grounds. Invite your own caterers or hire in food trucks, hog roasts or whatever you fancy.",
    "If you are having speeches, you will have advised the wedding planner so that she can make sure they begin when you have agreed. If you have a wedding cake, she will ensure that you are in the right place at the right time  to cut it. (A set time that you will have previously given her)",
    "Your wedding planner will also be around to make sure that the food trucks open up and close down at the time you prescribe. She will make sure that the evening entertainment begins as planned, so that you have no stresses or responsibilities on your big day.",
    "She will ask the assembled throng to stand when you enter the room as a married couple. She will also announce the first and last dances. The whole day will be seamlessly and discreetly managed to give you the day of your dreams."
  ],

  // End Included section ---------------------

  // Facilities section ---------------------

  facilitiesText: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vehicula massa convallis elit venenatis congue. Aenean ex magna, accumsan at erat id, sollicitudin pretium libero. Pellentesque dictum nulla laoreet mollis pretium. Sed sodales, arcu sit amet facilisis molestie, risus turpis faucibus purus, a sagittis sem urna eu neque. Quisque purus quam, tristique vel viverra vitae, tincidunt ut dolor. Aenean quis maximus nunc. Phasellus eu erat a lectus varius hendrerit. Vivamus eget eros in tellus dictum elementum. Aenean tincidunt ex sit amet diam blandit, et facilisis ipsum consectetur. Donec scelerisque vestibulum ante et dapibus. Aliquam tincidunt sit amet augue at imperdiet. Sed eget massa ut elit fermentum maximus. Maecenas mattis laoreet nulla eget commodo. Nulla at nibh in quam consectetur convallis sed non tellus. Nam ut luctus lectus. Etiam suscipit ac urna dapibus placerat.",
    "Vestibulum vitae justo varius, rutrum ipsum id, sagittis neque. Praesent commodo eget neque ut mattis. Phasellus ut justo erat. Nam accumsan orci lobortis, ullamcorper ligula vel, lacinia ante. Vivamus vulputate risus blandit sapien dapibus gravida. Proin non tempus turpis. Cras non ipsum sollicitudin, pharetra augue et, mattis lacus. Morbi sit amet tellus dictum erat ornare finibus. Aliquam erat volutpat.",
  ],

  // End Facilities section ---------------------

  // Inspiration section ---------------------

  inspirationText: [
    "**Romantic Garden**",
    "",
    "Soft hues, delicate lighting, and plenty of florals typically make up a romantic wedding. For this theme, envision pastels, hanging lights (even chandeliers) in the trees, calligraphy on your signage, and a flower wall.",
    "",
    "**Alternative**",
    "",
    "Buck tradition with an alternative wedding theme, and roll with it any way you want; this is your day! If cool, moody colour palettes and creative stand-ins match your style better than bright floral and traditional ‘done that’ wedding décor, then an alternative theme has your name written all over it. Push the boundaries as much as you want.",
    "",
    "**Vintage**",
    "",
    "For a vintage-inspired wedding, Arrive vintage vintage style in an old Porsche or classic car. Serve afternoon tea on the lawn in a mismatch of vintage teacups and tea pots. Raid car boots, the attic and grannies cupboards and consider asking guests to bring picnics!",
    "",
    "**Whimsy**",
    "",
    "For the whimsical couple, your wedding will be one full of bright splashes of colour and quirky, bohemian components. Incorporate design elements like multicolored balloons, streamers, punchy floral arrangements, and mismatched chairs. Walk up the aisle on a carpet of rose petals.",
    "",
    "**Rustic**",
    "",
    "String lights, mason jars, twine, lace, and all wood everything contributes to the simplicity and natural vibe of this theme. If you're a crafter or have an artistic bent, then rustic wedding décor will be your cup of tea. Consider hay bales as casual seating around fire pits and provide jars of marshmallows.",
    "",
    "**Bohemian**",
    "",
    "Calling all boho brides—if this matches your own personal style, then create a cool and carefree wedding. Opt for simplistically elegant decorations, with oriental rugs lining the aisle and altar and hanging macramé jam jars with tea lights. Guests can bring their own rugs for the garden. Take a note from actress Troian Bellisario's wedding, which is the stuff of boho dreams.",
    "",
    "**Formal / Traditional**",
    "",
    "If you envision you and your guests dressing to the nines at a black tie do, then your decorative theme will have to match. Pull out all the stops and include formal seating, chandeliers, ornate flower arrangements and an exquisite head table overlooking the grand oaks that border the lake",
    "",
    "**Christmas**",
    "",
    "Take your winter wedding to the next level with Christmas tree décor and ornaments. Serve warming winter cocktails or mulled wine. Hope for a little snow on the day or hire a snow machine! Play \"All I Want for Christmas Is You\" and provide your guests with Santa hats and bubble wands. Wrap your favours in Christmas paper and tinsel.",
    "",
    "**Anything Goes**",
    "",
    "With the stunning Menagerie venue, anything you design will look good. The most important thing is that whatever you style will be uniquely yours, as no two weddings here are ever the same!"
  ],

  // End Inspiration section ---------------------

  // Pricing section ---------------------

  pricingText: [
    "Choose either:",
    "> Monday from 1pm* to Wednesday at 12 noon*.",
    "> Wednesday from 1pm* to Friday at 12 noon*",
    "* Wedding couple, family and friends arrive from 4pm and vacate by 11am (caterers, decorators, florists etc have early access and later finish)",
    "£5,000 inc. VAT"
  ],

  // End Pricing section ---------------------

  // Team section ---------------------

  team: [
    {
      name: "Jane",
      title: "Owner",
      imgURL: "https://d2v35ww0dp4pjd.cloudfront.net/jane.png",
    },
    {
      name: "Pat",
      title: "Wedding Planner",
      imgURL: "https://d2v35ww0dp4pjd.cloudfront.net/pat.png",
    },
    {
      name: "Matt",
      title: "Site Manager",
      imgURL: "https://d2v35ww0dp4pjd.cloudfront.net/matt.png",
    },
    {
      name: "Blessing",
      title: "House Keeper",
      imgURL: "https://d2v35ww0dp4pjd.cloudfront.net/blessing.png",
    }
  ],

  // End Team section ---------------------

  //   Contact Section --------------

  contactSubHeading: "Get in touch for more info",
  social: [
    // Add Or Remove The Link Accordingly
    {
      img: airbnbIcon,
      url: "https://www.airbnb.co.uk/rooms/5845404",
    },
    {
      img: emailIcon,
      url: "mailto:enquiries@menagerievenue.co.uk",
    },
  ],

  // End Contact Section ---------------
}
